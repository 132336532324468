<template>
   <div class="container-fluid" id="pakua-app">
        <div class="row justify-content-center margin-mobile">
            <div class="col-md-4 my-auto" style="padding-right: 20px;padding-left: 20px">

                <h2 class="mb-2 text-capitalize" style="font-weight: 300">DOWNLOAD <span style="font-weight: 700;color: #242a37"> OUR</span> APP</h2>
                <p>
                    Book tickets quickly, safe and on the go  <br>through  Mysafari  Passenger Application
<br>
                </p>
                <div class="d-flex">
                <div class="wow zoomIn" style="margin-right: 1rem" data-wow-delay="0.2s">
                    <a href="https://play.google.com/store/apps/details?id=com.mysafari.passenger" target="_blank">
                    <img src="@/assets/icons/googleplay.png" alt="Google Play" width="200" class="img-fluid">
                    </a>
                </div>

                </div>
            </div>

            <div class="col-md-4">
                <img src="@/assets/images/phone-2.png" class="img-fluid laptop" style="visibility: hidden">
            </div>
        </div>
    </div>
</template>

<script>
export default {
computed:{
    programName() {
      return getComputedStyle(document.documentElement).getPropertyValue('--app-title').trim();
    }, 
}
}
</script>

<style>

</style>