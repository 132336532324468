<template>
  <Navbar />

  <section id="hero" class="background-image hero-background-image"
    data-background="url(assets/rev-slider-files/assets/logo22_mysafari.jpg)">
    <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
      <div class="intro_title">
        <h3 class="mt-0 text-capitalize" style="font-weight: 700;font-size: 3rem;"></h3>
      </div>
    </div>
  </section>
  <main>
    <div class="container margin_60">
      <div class="main_title">
        <!-- <h2 class="mb-2 text-capitalize">Order Confirm and Payment</h2> -->
      </div>

      <div class="row justify-content-center" v-if="!isLoading">
        <div class="col-md-4  pe-4 wow zoomIn " data-wow-delay="0.2s" v-for="(booking, index) in listOfBooking"
          :key="index">
          <div class="box">
            <h4 class="text-center mt-3" style="font-weight: 700;color: white;">
              Confirm Reservation
            </h4>
            <div class="content" style="padding: 15px 15px 30px 15px; margin-bottom: 20px;">
              <h5>Personal Detail</h5>
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td class="text-end">{{ booking.passenger.name }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td class="text-end">
                      {{ booking.passenger.phone_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td class="text-end">{{ booking.passenger.email }}</td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td class="text-end">{{ booking.passenger.age_group }}</td>
                  </tr>
                </tbody>
              </table>
              <h5>Safari Detail</h5>
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <td>From:</td>
                    <td class="text-end">{{ booking.from }}</td>
                  </tr>
                  <tr>
                    <td>To:</td>
                    <td class="text-end">{{ booking.to }}</td>
                  </tr>
                  <tr>
                    <td>Bus:</td>
                    <td class="text-end">{{ booking.trip.vehicle.name }}</td>
                  </tr>
                  <tr>
                    <td>Reg No:</td>
                    <td class="text-end">
                      {{ booking.trip.vehicle.registration_number }}
                    </td>
                  </tr>
                  <tr>
                    <td>Class</td>
                    <td class="text-end">
                      {{ booking.trip.vehicle.fleet_type }}
                    </td>
                  </tr>
                  <tr>
                    <td>Seat</td>
                    <td class="text-end">{{ booking.seat_number }}</td>
                  </tr>
                  <tr>
                    <td>Baggage</td>
                    <td class="text-end">No</td>
                  </tr>
                </tbody>
              </table>
              <h5 class="text-center">Choose Mode Of Payment</h5>
              <p class="text-center">
                <a @click="selectPaymentMode(payment_mode.partnerName)" role="button" :class="{
                  'btn-outline-warning':
                    payment_mode.partnerName == form.provider,
                }" v-for="(payment_mode, index) in listOfPaymentModes" :key="index" class="btn ">
                  <img :src="payment_mode.logoUrl" style="width: 100%;height: 25px;object-fit: contain;" />
                </a>
              </p>
            </div>
          </div>
          <!-- <p class="text-center mt-3">
            <a href="#" class="d-inline-block text-dark fw-bolder" @click.prevent="printDiv">
              <img src="@/assets/images/print.png" style="width: 100%;height: 25px;object-fit: contain;" />
              Print
            </a>

          </p> -->
        </div>
        <br>
        <div class="row justify-content-center pt-1">
          <div class="col-md-6 " v-if="form.provider">
            <br>
            <input style="text-align: center;" type="text" v-model="form.phone_number" class="form-control"
              placeholder="Enter phone number.eg 06**" />
          </div>
        </div>
        <div class="row justify-content-center pt-3">
          <div class="text-center">
            <button type="button" class="btn btn-secondary mr-1" @click="moveToSearch()">Back</button>
            <button type="button" class="btn btn-warning ml-1" @click="makePayment()"
              style="color: white;margin-left: 3%;">
              Proceed
            </button>
          </div>
        </div>
      </div>
      <div class="home" v-else>
        <Loading />
      </div>
    </div>
    <!-- End container -->
  </main>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Loading from "@/views/custom/Loading.vue";
export default {
  components: { Navbar, Loading },
  data() {
    return {
      isLoading: false,
      listOfBooking: [],
      listOfPaymentModes: [],
      form: {
        provider: "",
        phone_number: "",
        booking_number: "",
        additionalProperties: "",
      },
      error: "",
    };
  },
  methods: {
    moveToSearch(){
      this.$router.push({ name: "Search" });
    },
    printDiv() {
      const printableContent = this.$refs.printableDiv;

      

      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(`
<html>
  <link href="https://fonts.cdnfonts.com/css/fake-receipt" rel="stylesheet">
                
<head>
 <style>
 /* -------------------------------------
GLOBAL
A very basic CSS reset
------------------------------------- */
* {
margin: 0;
padding: 0;
font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
box-sizing: border-box;
font-size: 14px;
}

img {
max-width: 100%;
}

body {
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: none;
width: 100% !important;
height: 100%;
line-height: 1.6;
}

/* Let's make sure all tables have defaults */
table td {
vertical-align: top;
}

/* -------------------------------------
BODY & CONTAINER
------------------------------------- */
body {
background-color: #f6f6f6;
}

.body-wrap {
background-color: #f6f6f6;
width: 100%;
}

.container {
display: block !important;
max-width: 600px !important;
margin: 0 auto !important;
/* makes it centered */
clear: both !important;
}

.content {
max-width: 600px;
margin: 0 auto;
display: block;
padding: 20px;
}

/* -------------------------------------
HEADER, FOOTER, MAIN
------------------------------------- */
.main {
background: #fff;
border: 1px solid #e9e9e9;
border-radius: 3px;
}

.content-wrap {
padding: 20px;
}

.content-block {
padding: 0 0 20px;
}

.header {
width: 100%;
margin-bottom: 20px;
}

.footer {
width: 100%;
clear: both;
color: #999;
padding: 20px;
}
.footer a {
color: #999;
}
.footer p, .footer a, .footer unsubscribe, .footer td {
font-size: 12px;
}

/* -------------------------------------
TYPOGRAPHY
------------------------------------- */
h1, h2, h3 {
font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
color: #000;
margin: 40px 0 0;
line-height: 1.2;
font-weight: 400;
}

h1 {
font-size: 32px;
font-weight: 500;
}

h2 {
font-size: 24px;
}

h3 {
font-size: 18px;
}

h4 {
font-size: 14px;
font-weight: 600;
}

p, ul, ol {
margin-bottom: 10px;
font-weight: normal;
}
p li, ul li, ol li {
margin-left: 5px;
list-style-position: inside;
}

/* -------------------------------------
LINKS & BUTTONS
------------------------------------- */
a {
color: #1ab394;
text-decoration: underline;
}

.btn-primary {
text-decoration: none;
color: #FFF;
background-color: #1ab394;
border: solid #1ab394;
border-width: 5px 10px;
line-height: 2;
font-weight: bold;
text-align: center;
cursor: pointer;
display: inline-block;
border-radius: 5px;
text-transform: capitalize;
}

/* -------------------------------------
OTHER STYLES THAT MIGHT BE USEFUL
------------------------------------- */
.last {
margin-bottom: 0;
}

.first {
margin-top: 0;
}

.aligncenter {
text-align: center;
}

.alignright {
text-align: right;
}

.alignleft {
text-align: left;
}

.clear {
clear: both;
}

/* -------------------------------------
ALERTS
Change the class depending on warning email, good email or bad email
------------------------------------- */
.alert {
font-size: 16px;
color: #fff;
font-weight: 500;
padding: 20px;
text-align: center;
border-radius: 3px 3px 0 0;
}
.alert a {
color: #fff;
text-decoration: none;
font-weight: 500;
font-size: 16px;
}
.alert.alert-warning {
background: #f8ac59;
}
.alert.alert-bad {
background: #ed5565;
}
.alert.alert-good {
background: #1ab394;
}

/* -------------------------------------
INVOICE
Styles for the billing table
------------------------------------- */
.invoice {
margin: 40px auto;
text-align: left;
width: 80%;
}
.invoice td {
padding: 5px 0;
}
.invoice .invoice-items {
width: 100%;
}
.invoice .invoice-items td {
border-top: #eee 1px solid;
}
.invoice .invoice-items .total td {
border-top: 2px solid #333;
border-bottom: 2px solid #333;
font-weight: 700;
}

/* -------------------------------------
RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */
@media only screen and (max-width: 640px) {
h1, h2, h3, h4 {
font-weight: 600 !important;
margin: 20px 0 5px !important;
}

h1 {
font-size: 22px !important;
}

h2 {
font-size: 18px !important;
}

h3 {
font-size: 16px !important;
}

.container {
width: 100% !important;
}

.content, .content-wrap {
padding: 10px !important;
}

.invoice {
width: 100% !important;
}
}

@font-face {
  font-family: 'receipt'; /* Choose a name for your font */
  src: url('assets/fonts/fake-receipt.regular.otf') format('opentype'); /* Correct path to your OTF file */
}

body {
                    font-family: 'receipt', sans-serif;
                    /* Other CSS styles for your page */
            }

 </style>

 

</head>
<body>
  <table class="body-wrap">
<tbody><tr>
<td></td>
<td class="container" width="600">
    <div class="content">

        <table class="main" width="50%" cellpadding="0" cellspacing="0">
            <tbody><tr>
                <td class="content-wrap aligncenter">
                    <table width="100%" cellpadding="0" cellspacing="0">
                        <tbody><tr>
                            <td class="content-block">
                                <h2>Thanks for using our app</h2>
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block">
                                <table class="invoice">
                                    <tbody>
                                      <tr>
                                        <td><b>Personal Details</b></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table class="invoice-items" cellpadding="0" cellspacing="0">
                                                <tbody><tr>
                                                    <td>Name</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.phone_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Age</td>
                                                    <td class="alignright">${this.listOfBooking[0].passenger.age_group}</td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr><br>  
                                    <tr>
                                      
                                     <td style="padding-top:5%"><b>Safari Details</b></td>
                                     </tr>
                                     <tr>
                                        <td>
                                            <table class="invoice-items" cellpadding="0" cellspacing="0">
                                                <tbody><tr>
                                                    <td>From</td>
                                                    <td class="alignright">${this.listOfBooking[0].from}</td>
                                                </tr>
                                                <tr>
                                                    <td>To</td>
                                                    <td class="alignright">${this.listOfBooking[0].to}</td>
                                                </tr>
                                                <tr>
                                                    <td>Bus</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Reg No</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.registration_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Class</td>
                                                    <td class="alignright">${this.listOfBooking[0].trip.vehicle.fleet_type}</td>
                                                </tr>
                                                <tr>
                                                    <td>Seat</td>
                                                    <td class="alignright">${this.listOfBooking[0].seat_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Baggage</td>
                                                    <td class="alignright">No</td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr><br>  
                                    
                                </tbody></table>
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block">
                                
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block">
                              https://booking.mysafari.co.tz/
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
        <div class="footer">
            <table width="100%">
                <tbody><tr>
                    <td class="aligncenter content-block">Questions? Email <a href="mailto:"> info@hashtech.co.tz</a></td>
                </tr>
            </tbody></table>
        </div></div>
</td>
<td></td>
</tr>
</tbody></table>
</body>

</html>
`);

      printWindow.document.close();
      printWindow.print();
    },
    makePayment() {
      if (this.form.provider == "Tigopesa") {
        this.form.provider = "Tigo";
      }

      // check if user entered 10 digits
      const phoneNumber = this.form.phone_number;
      // Check if the number is not 10 digits
      if (phoneNumber.length !== 10) {
        alert("Please enter 10 digits.");
        return;
      }
      // Check if the number starts with zero
      if (!phoneNumber.startsWith("0")) {
        alert("Please enter a valid number that starts with zero.");
        return;
      }

      // Replace the first digit (0) with +255
      // this.form.phone_number = "+255" + phoneNumber.substring(1);

      // You can now use the formattedNumber for further processing if needed

      axios
        .post("checkout/mno", this.form)
        .then((response) => {
          
          
          if (response.data.success == false) {
            this.error = response.data.message;
          } else {
            let transactionId = response.data.transactionId;
            let message = response.data.message;
            localStorage.setItem("transactionId", transactionId);
            localStorage.setItem("checkout_success", message);
            this.$router.push({ name: "Ticket" });
          }
        })
        .catch((errors) => {
          this.error = errors.response.data.errors;
        });
    },
    getPaymentModes() {
      
      axios
        .get("mobile-money-channels")
        .then((response) => {
          this.listOfPaymentModes = response.data;
         
        })
        .finally(() => {
          setTimeout(
            function () {
              this.isLoading = false;
            }.bind(this),
            20
          );
        });
    },
    selectPaymentMode(partnerName) {
      const startIndex = 3; // Start index to remove "255"
      // const modifiedValue = "0" + this.listOfBooking[0].passenger.phone_number.substring(startIndex);

      this.form.provider = partnerName;
      this.form.phone_number = "";
      this.form.booking_number = this.listOfBooking[0].booking_number;
      this.form.additionalProperties = this.listOfBooking
        .map((booking) => booking.booking_number)
        .join(", "); //this.listOfBooking[0].booking_number
    },
  },
  computed: {},
  mounted() {
    document.title = `Complete Booking `;
    this.getPaymentModes();
    if (localStorage.getItem("stations")) {
      this.listOfBooking = JSON.parse(localStorage.getItem("bookings"));
    }
  },
};
</script>

<style scoped>
hr {
  border: none;
  border-top: 1px dotted #000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 99%;
}

.img-payment-mode {
  max-height: 25px;
}

tr {
  line-height: 10px;
  min-height: 10px;
  height: 10px;
}

#hero {
  position: relative;
  height: 250px;
}

@media (max-width: 767px) {
  .intro_title {
    padding-bottom: 0rem !important;
  }
}

@media (max-width: 767px) {
  .intro_title {
    padding-bottom: 0rem !important;
  }

  a.btn_1,
  .btn_1 {
    width: 100%;
    padding: 10px 100px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  #seat-map {
    border: 0px solid #eeeeee;
  }

  div.seatCharts-cell {
    width: 20% !important;
  }
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

h4 {
  color: var(--background-color2);
  font-size: 18px !important;
}

h4 small {
  color: #fff !important;
  font-size: 14px;
}

/* Element | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box {
  background-color: #171a24;
  padding: 20px;
}

/* Inline #6 | file:///C:/Users/Qali/Downloads/my-safari/my-safari/order-confirm.html */

.box .content {
  background: white;
  padding: 10px;
}

.box .content td {
  font-size: 14px;
  font-weight: bold;
}

.box .content h5 {
  font-size: 14px !important;
  color: var( --header-color);
}

.hero-background-image {
  background-image: var(--background-image);
}

/* code for html receipt */
</style>
