<template>
  <Navbar />
  <section class="breadcrumb-area bread-bg">
      <div class="breadcrumb-wrap">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-6">
                      <div class="breadcrumb-content">
                          <div class="section-heading">
                              <h2 class="sec__title text-white">Complete Booking</h2>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="bread-svg-box">
          <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
      </div>
  </section>
  <main>
    <div class="container margin_60">
      <div class="main_title">
        <!-- <h2 class="mb-2 text-capitalize">Order Confirm and Payment</h2> -->

      </div>

      <div class="row justify-content-center"  v-if="!isLoading" >
        <div class="col-md-4  pe-4 wow zoomIn " data-wow-delay="0.2s" v-for="(booking, index) in listOfBooking" :key="index">
          <div class="box">
            <img src="@/assets/images/check.png" style="width: 100%;height: 75px;object-fit: contain;">
            <h4 class="text-center mt-3">Payment Successful <br> 
              <small class="text-white">We have emailed you the receipt</small>
            </h4>
            <div class="content" style="padding: 15px 15px 30px 15px; margin-bottom: 20px;">
              <h5>Personal Detail</h5>
              <table class="table table-sm">
                <tbody>
                  <tr><td>Name</td><td class="text-end">{{ booking.passenger.name }}</td></tr>
                  <tr><td>Phone</td><td class="text-end">{{ booking.passenger.phone_number }}</td></tr>
                  <tr><td>Email</td><td class="text-end">{{ booking.passenger.email }}</td></tr>
                  <tr><td>Age</td><td class="text-end">{{ booking.passenger.age_group }}</td></tr>
                </tbody>
              </table>
              <h5>Safari Detail</h5>
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <td>From:</td>
                    <td class="text-end">{{ booking.from }}</td>
                  </tr>
                  <tr>
                    <td>To:</td>
                    <td class="text-end">{{ booking.to }}</td></tr>
                  <tr>
                  <td>Bus:</td>
                  <td class="text-end">{{ booking.trip.vehicle.name }}</td>
                </tr>
                <tr>
                  <td>Reg No:</td>
                  <td class="text-end">{{ booking.trip.vehicle.registration_number }}</td>
                </tr>
                <tr>
                  <td>Class</td>
                  <td class="text-end">{{ booking.trip.vehicle.fleet_type }}</td>
                </tr>
                <tr>
                  <td>Seat</td>
                  <td class="text-end">{{ booking.seat_number }}</td>
                </tr>
                <tr>
                  <td>Baggage</td>
                  <td class="text-end">No</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p class="text-center mt-3">
            <a href="#" class="d-inline-block text-dark fw-bolder"><img src="@/assets/images/pdf.png" style="width: 100%;height: 25px;object-fit: contain;"> PDF</a>
            <a href="#" class="d-inline-block text-dark fw-bolder"><img src="@/assets/images/print.png" style="width: 100%;height: 25px;object-fit: contain;"> Print</a>
          </p>
            
        </div>
        <div class="row justify-content-center pt-5">
          <div class="text-center">
            <button type="button" class="btn btn-secondary mr-1" @click="printTicket()">Print </button>
            <button type="button" class="btn btn-primary ml-1" @click="bookAgain()">Book Again</button>
          </div>
        </div>
      </div>
      <div class="home" v-else>
        <Loading />
      </div>
    </div>
  </main>
  
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
components: { Navbar, Loading },
data(){
    return {
      isLoading: false,
      checkoutSuccess: localStorage.getItem('checkout_success')
      ,error: ''
    }
},
methods: {
  bookAgain(){
    localStorage.clear()
    this.$router.push({ name: 'Home'})
  },
  printTicket(){
    
  },
},
computed: {

},
mounted () {
  document.title = `Complete Booking - Hashtech`
  this.checkoutSuccess = localStorage.getItem('checkout_success')

},

}
</script>

<style scoped>
  hr {
border: none;
border-top: 1px dotted #000;
color: #fff;
background-color: #fff;
height: 1px;
width: 99%;
}
.img-payment-mode{
  max-height: 25px;
}
tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}
</style>