<template>

  <Navbar />
  <div class="home" v-if="!isLoading">
    <section
      id="hero"
      class="slider1"
    >


      <div class="feature1">
      </div>





      <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
        <div class="intro_title">
          <!-- <h3
            class="mt-0 "
            style="font-weight: 700;font-size: 40px;line-height: 0.8; text-align: center; display: inline;color: #4CDF37; "
          >
            USAFIRI KIGANJANI
          </h3> -->
          <br />
        </div>
        <div id="search_bar_container" >

          <div class="container" >
            <div class="search_bar">
              <div class="tab-content">
                <div class="tab-pane fade active show" id="tours">
                  <h3
                    class="text-capitalize"
                    style="color: var(--background-color2);font-weight: bold;letter-spacing: 1px;"
                  >
                    Type to Search Destination
                  </h3>
                  <form action="#">
                    <div class="row g-0 custom-search-input-2">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <input
                            
                              v-model="journey.from_search"
                              class="form-control"
                              type="text"
                              placeholder="From..."
                              id="autocomplete"
                              required
                              @input="$event.target.composing = false"
                            />
                          </div>
                          <div
                            v-if="
                              journey.from_search && matchingFromRegions.length
                            "
                            class="position-absolute w-100 bg-white mt-2 overflow-hidden rounded shadow-sm"
                            style="max-height: 15rem; z-index: 10;"
                          >
                            <ul class="list-unstyled">
                              <template v-if="matchingFromRegions.length">
                                <li
                                  v-for="(region, index) in matchingFromRegions"
                                  :key="index"
                                  class="px-3 py-2 pe-auto hover:bg-gray-200 text-center"
                                >
                                  <a
                                    @click="captureRegion(region, 'from')"
                                    role="button"
                                    class="pe-auto d-flex justify-content-start"
                                  >
                                    {{ region.name }}
                                  </a>
                                </li>
                              </template>
                              <li
                                v-else-if="
                                  journey.from_search &&
                                    !matchingFromRegions.length &&
                                    capturedRegionName.from !=
                                      journey.from_search
                                "
                                class="px-3 py-2 text-center"
                              >
                                No Matching Results
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            
                            <input
                              v-model="journey.to_search"
                              class="form-control"
                              type="text"
                              placeholder="To..."
                              required
                              @input="$event.target.composing = false"
                            />
                          </div>
                          <div
                            v-if="journey.to_search && matchingToRegions.length"
                            class="position-absolute w-100 bg-white mt-2 overflow-hidden rounded shadow-sm"
                            style="max-height: 15rem; z-index: 10;"
                          >
                            <ul class="list-unstyled">
                              <template v-if="matchingToRegions.length">
                                <li
                                  v-for="(region, index) in matchingToRegions"
                                  :key="index"
                                  class="px-3 py-2 pe-auto hover:bg-gray-200 text-center"
                                >
                                  <a
                                    @click="captureRegion(region, 'to')"
                                    role="button"
                                    class="pe-auto d-flex justify-content-start"
                                  >
                                    {{ region.name }}
                                  </a>
                                </li>
                              </template>
                              <li
                                v-else-if="
                                  journey.to_search &&
                                    !matchingToRegions.length &&
                                    capturedRegionName.to != journey.to_search
                                "
                                class="px-3 py-2 text-center"
                              >
                                No Matching Results
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input
                            v-model="journey.trip_date"
                            class="date-pick form-control"
                            type="date"
                            placeholder="When.."
                            required
                          />
                          <!--                      <input class="form-control" type="date" name="dates" placeholder="When.." required>-->
                          <!-- <i class="icon-calendar"></i> -->
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-lg-10">
                        <div class="section-tab section-tab-2 pb-2 text-center">
                          <ul
                            class="nav nav-tabs justify-content-center"
                            id="myTab3"
                            role="tablist"
                          >
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                :class="{
                                  active: journey.journey_type_id == '1',
                                }"
                                @click="assignBookingType('1')"
                                id="one-way-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="one-way"
                                aria-selected="true"
                                style="color:#242a37;font-weight: bold;"
                              >
                                One way
                              </a>
                            </li>
                            <!-- <li class="nav-item">
													<a class="nav-link" :class="{ 'active': journey.journey_type_id == '2' }" id="round-trip-tab" data-toggle="tab"  @click="assignBookingType('2')" role="tab" aria-controls="round-trip" aria-selected="false" style="font-weight: bold;">
														Go & Return
													</a>
												</li>
												<li class="nav-item">
													<a class="nav-link" :class="{ 'active': journey.journey_type_id == '3' }" data-toggle="tab" @click="assignBookingType('3')" role="tab" aria-controls="round-trip" aria-selected="false" style="font-weight: bold;">
														Private Hire
													</a>
												</li> -->
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-10">
                        <button
                          @click="findJurney()"
                          class="btn btn-block btn_search"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <main class="" style="margin-top: 18rem">
      <div class="container margin_60 pb-0 bg-white">
        <div class="main_title margin-mobile" style="padding-bottom: 100px;">
          <p class="mb-1 p-mobile" style="font-size: 30px;">
            Stress Free Search
          </p>
          <h2 class="mb-2 text-capitalize">Traveling Across Tanzania</h2>
          <p>
            Your one-stop destination for stress free ticket reservations.
          </p>
        </div>
      </div>
      <div class="container-fluid" id="travel-style">
        <div class="row justify-content-center margin-mobile">
          <div class="qualities col-md-8" style="margin-top: -100px;">
            <div class="row">
              <div class="col-md-4 wow zoomIn" data-wow-delay="0.2s">
                <div class="feature_home">
                  <div class="circle-icon">
                    <img src="@/assets/icons/warranty1.png" width="70" alt="" />
                  </div>
                  <h3></h3>
                  <p>
                    Welcome to <span class="program-name">{{ programName }}</span>'s award winning loyalty program, where your road travel experiences are rewarded like never before!
                  </p>
                </div>
              </div>
              <div class="col-md-4 wow zoomIn" data-wow-delay="0.4s">
                <div class="feature_home">
                  <div class="circle-icon">
                    <img
                      src="@/assets/icons/secure-shield1.png"
                      width="70"
                      alt=""
                    />
                  </div>
          
                  <p>
					Get the best deals available from our top bus routes for you
to choose from, combining quality and economy.

                  </p>
                </div>
              </div>
              <div class="col-md-4 wow zoomIn" data-wow-delay="0.6s">
                <div class="feature_home">
                  <div class="circle-icon">
                    <img
                      src="@/assets/icons/fast-delivery1.png"
                      width="70"
                      alt=""
                    />
                  </div>
                  <h3></h3>
                  <p>
					Travel in style with <span class="program-name">{{ programName }}</span> within Tanzania and beyond

                  </p>
                  <!--          <a href="about.html" class="btn_1 outline">Read more</a>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End row -->
        <div class="row justify-content-center margin-mobile">
          <div class="col-md-4">
            <img
              src="@/assets/images/Travelers.png"
              alt="Laptop"
              class="img-fluid laptop"
            />
          </div>
          <div class="col-md-4">
            <h2 class="mb-2 text-capitalize" style="font-weight: 700">
              Travel in style
            </h2>
            <p>
              Travel in style with <span class="program-name">{{ programName }}</span> within Tanzania and beyond.Embrace a new standard of travel and journey in style with
              <span class="program-name">{{ programName }}</span>. Let us redefine your perception of elegance and
              luxury on the road as we take you to your dream
              destinations in the most stylish and comfortable manner
              possible.
            </p>
            <ul class="list_order">
              <li>
                <span><i class="fa-icon-check "></i> </span>Custom pickup and
                drop points
              </li>
              <li>
                <span><i class="fa-icon-check"></i></span>Track your ongoing
                trip
              </li>
              <li>
                <span><i class="fa-icon-check"></i></span>Get rewarded by
                distance you travelled
              </li>
              <li>
                <span><i class="fa-icon-check"></i></span>Recover your ticket
                anytime
              </li>
              <li>
                <span><i class="fa-icon-check"></i></span>Easy payment method
              </li>
            </ul>
          </div>
        </div>
      </div>
      <DownloadApp />
    </main>
  </div>
  <div class="home" v-else>
    <Loading />
  </div>

  <div id="myModal" class="modal fade" style="padding-top: 20%;">
    <div class="modal-dialog modal-confirm">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box1">
                    <i class="material-icons">&#xE876;</i>
                </div>
                <h4 class="modal-title w-100">Thank you!</h4>
            </div>
            <div class="modal-body">
                <p class="text-center">Booking has been done Successfully!</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="block" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import { computed, ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Loading from "@/views/custom/Loading.vue";
import DownloadApp from "@/components/DownloadApp.vue";

export default {
  name: "Home",
  emits: ["paginate"],
  components: {
    Navbar,
    Footer,
    Loading,
    DownloadApp,

  },
  data() {
    return {
      val: "",
      currentPage: "Jobs",
      previousPage: "",
      journey: {
        from_search: "",
        from: "",
        to_search: "",
        to: "",
        trip_date: new Date().toISOString().substr(0, 10),
        journey_type_id: "1",
      },
      capturedRegionName: {
        from: "",
        to: "",
      },
      regions: {},
      isLoading: true,
      jobs: { current_page: 1 },
      allJobs: {},
      data: {},
      errors: false,
      classes: ["d-none"],
      message: "",
      currentPage:""
    };
  },
  methods: {

    triggerDropdown(val) {
    if (val === 'from') {
      this.showFromDropdown = true;
      this.journey.from_search = val;
    } else if (val === 'to') {
      this.showToDropdown = true;
      this.journey.to_search = val;
    }
  },
    getRegions() {
      axios
        .get("/passenger/regions")
        .then((response) => {
          this.regions = response.data.regions;
        })
        .catch((errors) => {
          this.CountryErrors = error.response.data.errors;
        })
        .finally(() => {
          setTimeout(
            function() {
              this.isLoading = false;
            }.bind(this),
            20
          );
        });
    },
    captureRegion(region, action) {
      if (action == "to") {
        this.journey.to = region.id;
        this.journey.to_search = region.name;
        this.matchingToRegions = [];
        this.capturedRegionName.to = region.name;
      } else {
        this.journey.from = region.id;
        this.journey.from_search = region.name;
        this.matchingFromRegions = [];
        this.capturedRegionName.from = region.name;
      }
    },
    assignBookingType(journey_type_id) {
      this.journey.journey_type_id = journey_type_id;
    },
    findJurney() {
      if (this.capturedRegionName.from == this.journey.from_search) {
        localStorage.setItem("from", this.journey.from);
        localStorage.setItem("from_search", this.journey.from_search);
      }
      if (this.capturedRegionName.to == this.journey.to_search) {
        localStorage.setItem("to", this.journey.to);
        localStorage.setItem("to_search", this.journey.to_search);
      }
      if (this.journey.trip_date) {
        localStorage.setItem("trip_date", this.journey.trip_date);
      }
      localStorage.setItem("journey_type_id", this.journey.journey_type_id);
      this.$router.push({ name: "Search" });
    },
  },
  computed: {
    programName() {
      return getComputedStyle(document.documentElement).getPropertyValue('--app-title').trim();
    },
    matchingFromRegions: function() {
      var self = this;
      
      if (
        this.regions.length &&
        this.journey.from_search &&
        this.capturedRegionName.from != this.journey.from_search
      ) {
        // 
        return this.regions.filter(function(regionFilter) {
          return (
            regionFilter.name
              .toLowerCase()
              .indexOf(self.journey.from_search.toLowerCase()) !== -1 ||
            regionFilter.code
              .toLowerCase()
              .indexOf(self.journey.from_search.toLowerCase()) !== -1
          );
        });
      } else {
        return {};
      }
    },

    matchingToRegions: function() {
      var self = this;
      if (
        this.regions.length &&
        this.journey.to_search &&
        this.capturedRegionName.to != this.journey.to_search
      ) {
        return this.regions.filter(function(regionFilter) {
          return (
            regionFilter.name
              .toLowerCase()
              .indexOf(self.journey.to_search.toLowerCase()) !== -1 ||
            regionFilter.code
              .toLowerCase()
              .indexOf(self.journey.to_search.toLowerCase()) !== -1
          );
        });
      } else {
        return {};
      }
    },
  },
  head() {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '@/assets/icons/logo1.jpeg'
        }
      ]
    };
  },
  mounted() {




    if (sessionStorage.getItem('bookingSuccessful') === 'true') {
      setTimeout(() => {
            // modal
            $(document).ready(function(){
        $("#myModal").modal('show');
    });

        }, 1000);

    // You can clear the flag if needed
    sessionStorage.removeItem('bookingSuccessful');
  }
  


    document.title = ` Home`;
    this.getRegions();
  },
};

// adding the logic to handle the slide show events::


// slide show logic ends here
</script>
<style scoped>
.section-pb-0 {
  padding-bottom: 0px !important;
}
.clients {
  padding-top: 10%;
}

.banner.trans {
  min-height: 450px;
}
.banner {
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 620px;
  align-items: center;
  position: relative;
  background-position: center;
  padding: 80px 0;
}

.banner h1 {
  color: #ffffff;
  text-shadow: none;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 3px;
}

.card {
  border: none;
}
@media (max-width: 1000px) {
  .hidden-mobile {
    display: none;
  }
}
.auth_button {
  min-width: 7.65rem;
  max-width: 7.65rem;
}
.position-center {
  position: relative;
}

.side-buttons {
  position: absolute;
  right: 0px;
  top: 40%;
  position: fixed;
}
.btn-right {
  padding-top: 5px;
}
#sideBtn {
  transition: all 0.2s;
}

/* the card responsible for the glow effect */
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  padding: 1.5rem;
  margin-top: 25px;
  background: white;
  color: #48abe0;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
.hero-carousel .carousel-slide {
  opacity: 0;
  transition: opacity 1s;
}

.hero-carousel .carousel-slide.carousel-active {
  opacity: 1;
}

/* for the top slider */
.feature1 {

}

.slider1 {
   background-repeat: no-repeat;
   background-size: cover;
   width: 100%;
   height: 80vh;
   transition: all .2s ease-in-out;
   animation: slide 10s infinite;
}

.overlay1 {
   color: #fff;
   width: 100%;
   height: 80vh;
   background-color: rgba(0, 0, 0, 0.5);
   transition: all .2s ease-in-out;
}



@keyframes slide {
  0% {
  
    background-image:var(--slide-img-1);
  }
  50% {
   
    background-image: var(--slide-img-2);
  }
  100% {
   
    background-image: var(--slide-img-3);
  }

}

/* THE CARD FOR THE WORDING */
#header-text{
text-shadow: 0 0 7px #32de84;

color: white;
} 

@keyframes filling {
  from{
    background-position: center 25%;
  }
  to {
    background-position: center 50%;
  }
}

.container-text {
  background-image:  url(https://static.pexels.com/photos/4827/nature-forest-trees-fog.jpeg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color:  #FFFFFF;
  padding-top: 20px;
  font-size: 170px;
  font-family: 'Bungee', cursive;
  animation: filling 3s ease forwards;
}

/* success modal */
body {
        font-family: 'Varela Round', sans-serif;
    }

    .modal-confirm {
        color: #636363;
        width: 325px;
        font-size: 14px;
    }

    .modal-confirm .modal-content {
        padding: 20px;
        border-radius: 5px;
        border: none;
    }

    .modal-confirm .modal-header {
        border-bottom: none;
        position: relative;
    }

    .modal-confirm h4 {
        text-align: center;
        font-size: 26px;
        margin: 30px 0 -15px;
    }

    .modal-confirm .form-control,
    .modal-confirm .btn {
        min-height: 40px;
        border-radius: 3px;
    }

    .modal-confirm .close {
        position: absolute;
        top: -5px;
        right: -5px;
    }

    .modal-confirm .modal-footer {
        border: none;
        text-align: center;
        border-radius: 5px;
        font-size: 13px;
    }

    .modal-confirm .icon-box1 {
        color: #fff;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -70px;
        width: 95px;
        height: 95px;
        border-radius: 50%;
        z-index: 9;
        background: var(--background-color);
        padding: 15px;
        text-align: center;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    }

    .modal-confirm .icon-box1 i {
        font-size: 58px;
        position: relative;
        top: 3px;
    }

    .modal-confirm.modal-dialog {
        margin-top: 80px;
    }

    .modal-confirm .btn {
        color: #fff;
        border-radius: 4px;
        background: var(--background-color);
        text-decoration: none;
        transition: all 0.4s;
        line-height: normal;
        border: none;
    }

    .modal-confirm .btn:hover,
    .modal-confirm .btn:focus {
        background: var(--background-color);
        outline: none;
    }

    .trigger-btn {
        display: inline-block;
        margin: 100px auto;
    }

    .block {
  display: block;
  width: 100%;
  border: none;
  background-color:var(--background-color);
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

</style>
