<template>
  <router-view/>
</template>

<script>


export default {
  components: {  },
   watch: {
        $route: {
            immediate: true,
            handler(to, from) {
              
                const appTitle = getComputedStyle(document.documentElement).getPropertyValue('--app-title');
                document.title = to.meta.title || appTitle;
            }
        },
   }
}
</script>

<style>
@font-face {
    font-family: mark_pro;
    src: url('~@/assets/fonts/mark_pro/mark_heavy.ttf');
}

@font-face {
    font-family: mark_bold;
    src: url('~@/assets/fonts/mark_pro/mark_bold.ttf');
}

@font-face {
    font-family: mark_light;
    src: url('~@/assets/fonts/mark_pro/mark_light.ttf');
}

/* font for handwriting */



</style>


