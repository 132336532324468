<template>
    <!-- <div class="loading-wrap">
        <div class="loading">
            <div class="loading-dots"><span class="dot"></span><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>
        </div>
    </div> -->
    <div id="preloader">
        <div class="sk-spinner sk-spinner-wave">
            <div class="sk-rect1"></div>
            <div class="sk-rect2"></div>
            <div class="sk-rect3"></div>
            <div class="sk-rect4"></div>
            <div class="sk-rect5"></div>
    </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .loading-wrap{
      height: 100vh;
      position: relative;
  }
  .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
  }
  .title{
      margin-bottom: 50px;
  }
  
  .dot{
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: #bbb;
      display: inline-block;
      animation: blink 1.4s infinite;
  }
  
  @keyframes blink {
      0% {
          opacity: 0.2;
      }
      20%{
          opacity: 1;
      }
      80%{
          opacity: 0.4;
      }
      100%{
          opacity: 0.2;
      }
  }
  
  .dot:nth-child(1){
      background-color: var(--background-color2);
  }
  .dot:nth-child(2){
      background-color: #242a37;
      animation-delay: 0.2s;
  }
  .dot:nth-child(3){
      background-color: var(--background-color2);
      animation-delay: 0.4s;
  }
  .dot:nth-child(4){
      background-color: #242a37;
      animation-delay: 0.6s;
  }
  </style>