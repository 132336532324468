import { createRouter, createWebHistory } from 'vue-router'
import 'regenerator-runtime/runtime';
import Home from '@/views/Home.vue'
import Search from '@/views/Search.vue'
import Booking from '@/views/Booking.vue'
import CompleteBooking from '@/views/CompleteBooking.vue'
import Ticket from '@/views/Ticket.vue'
import downloadPdf from '@/views/downloadPdf.vue'

import PaymentStatus from '../views/PaymentStatus.vue'
import NotFound from '../views/404.vue'

const routes = [
  {
    path: '/', name: 'Home',component: Home
  },
  {
    path: '/downloadPdf/:dataUrl', // Define a named parameter dataUrl
    name: 'downloadPdf',
    component: downloadPdf
  },
  {
    path: '/search', name: 'Search',component: Search
  },
  {
    path: '/booking/:id', name: 'Booking',component: Booking
  },
  {
    path: '/booking/complete', name: 'CompleteBooking',component: CompleteBooking
  },
  {
    path: '/booking/ticket', name: 'Ticket',component: Ticket
  },
  {
    path: '/booking/payment', name: 'PaymentStatus',component: PaymentStatus
  },
  // catch all 404 pages 
  {
    path: '/:catchAll(.*)',name: 'NotFound',component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return document.getElementById('app').scrollIntoView();
    }
  }
})


function loggedIn(){
  return localStorage.getItem("user_data")
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
        query: {redirect: to.fullpath}
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
